.App{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #076181;
}

.container {
  padding: 50px 120px;
  border: white 3px solid;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap:20px;
  align-items: center;
}

#main-text {
  color: white;
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
}

#main-icon {
}